
import { Spinner, Button, Progress, FormControl, FormLabel, InputGroup, InputLeftAddon, Input, InputRightAddon, Divider, Switch, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { Twitter, Instagram, Linkedin, Facebook, Link, ArrowLeft, Controller } from 'react-bootstrap-icons';
import { PaystackConsumer } from 'react-paystack';
import { PAYSTACK_KEY } from '../services/constant';
import thankyou from '../assets/svgs/thank_you.svg';
import facebook from '../assets/svgs/facebook.svg';
import twitter from '../assets/svgs/twitter.svg';
import whatsapp from '../assets/svgs/whatsapp.svg';
import bg from "../assets/svgs/bg.svg";
import { redirect, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import Header from './header';
import bg_p from '../assets/svgs/bg_pattern.svg';

function Success() {
    const navigate = useNavigate();
    const toast = useToast();
    const campaign = useContext(GlobalContext);

    useEffect(() => {
        if (!campaign?.get?.url) {
            redirect('https://motito.co');
        }
    }, [])


    return (
        <div >
            <img className='absolute max-w-[500px] mx-auto object-fill' src={bg_p} alt="" />
            <Header />

            <div className='max-w-[500px] px-4 mx-auto space-y-4 mt-3 mb-10 '>
                <div className='text-center w-fit mx-auto'>
                    <img src={thankyou} />
                </div>
                <div className='text-center' >
                    <p className='text-2xl font-bold text-[#2E384B]'>Thank you for your donation!</p>
                </div>
                <div className='text-center'>
                    <p>
                        You’ve successfully contributed GHS <span className='font-bold'>{campaign?.get?.user_data?.amount?.toLocaleString() ?? 0.0}</span>, to <span className='font-bold'>{campaign?.get?.user_name} </span> fundraising. A receipt has been sent as an SMS to your phone number.
                    </p>
                </div>
                <div className='text-center'>
                    <p className='text-xl font-bold text-[#2E384B]'>Help spread the word.</p>
                    <p>
                        You can share this campaign to spread the word and help <span className='font-bold'>{campaign?.get?.user_name} </span> reach his target faster.
                    </p>
                </div>

                <div >
                    <p className='text-sm'>Copy Link </p>
                    <div className='flex gap-3'>
                        <Input readOnly value={campaign?.get?.url ?? ''} className='border p-4 rounded-md flex-auto' />
                        <Button className=' text-white hover:bg-opacity-90 bg-[#0B419C]' onClick={() => {
                            navigator.clipboard.writeText(campaign?.get?.url ?? '');
                            toast({
                                title: 'Copied !!!',
                                description: "Sharing link has been copied",
                                position: 'top',
                                duration: 2000,
                            })
                        }} >
                            Copy
                        </Button>
                    </div>
                </div>

                <div className='flex gap-8 w-fit mx-auto text-center'>
                    <div onClick={() => {
                        window.open(`https://wa.me/?text=${campaign?.get?.url}`, '_blank')
                    }} className='w-fit cursor-pointer' >
                        <img className='mx-auto' src={whatsapp} alt="" />
                        <p>What's App</p>
                    </div>

                    <div onClick={() => {
                        window.open(`http://www.facebook.com/sharer/sharer.php?t=${campaign?.get?.url}`, '_blank')
                    }} className='w-fit cursor-pointer'>
                        <img className='mx-auto' src={facebook} alt="" />
                        <p>Facebook</p>
                    </div>

                    <div onClick={() => {
                        window.open(`http://www.twitter.com/intent/tweet?text=${campaign?.get?.url}`, '_blank')
                    }} className='w-fit cursor-pointer'>
                        <img className='mx-auto' src={twitter} alt="" />
                        <p>Twitter</p>
                    </div>

                </div>

            </div >
        </div >
    )

}

export default Success