

import React from 'react'
import { Linkedin, Twitter, Instagram, Facebook } from 'react-bootstrap-icons'

function Header() {
    return (
        <div className='w-full ' style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex justify-between items-center container mx-auto  h-20 px-4 md:px-0'>
                <p className='text-2xl font-bold text-[#0B419C]'>
                    motito
                </p>

                <div className='flex gap-4'>
                    <a href="https://www.linkedin.com/company/motito/" target='_blank'><Linkedin /></a>
                    <a href="https://twitter.com/motitohq" target='_blank'> <Twitter /></a>
                    <a href="https://instagram.com/motitohq" target='_blank'><Instagram /></a>
                    <a href="https://facebook.com/motitohq" target='_blank'>  <Facebook /></a>
                </div>
            </div>
        </div>
    )
}

export default Header