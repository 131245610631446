"use client"
import {
    Progress, Button, FormControl,
    FormLabel, InputGroup, InputRightAddon,
    InputLeftAddon, Input, Divider, Switch,
    Spinner,
    NumberInput,
    NumberInputField
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { ArrowLeft } from 'react-bootstrap-icons'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Campaign } from '../interface'
import { useForm, Controller } from 'react-hook-form'
import { PAYSTACK_KEY, PAYSTACK_KEY_PROD } from '../services/constant'
import { GlobalContext } from '../App'
import PaystackPop from '@paystack/inline-js';
import { useToast } from '@chakra-ui/react'
import Header from './header'
import { isValidPhoneNumber } from 'libphonenumber-js';
import IntlTelInput from 'react-intl-tel-input'
import '../phone_number.scss'



function CampaignPayment() {
    const navigate = useNavigate();


    const toast = useToast();
    const [phone_number, setPhoneNumber] = useState<string>('');
    const [amount, setAmount] = useState<any>();
    const [loading, setLoading] = useState(true)
    const [campaign, setCampaign] = useState<Campaign | undefined>();
    const { control, handleSubmit, setValue, getValues, watch, formState: { isValid } } = useForm();
    const ctx = useContext(GlobalContext);

    let params = useParams();

    let formData = watch()

    let items = [10, 50, 70, 100]


    useEffect(() => {
        getData();
    }, [])

    let onSubmitForm = (data: any) => {

        if (!isValidPhoneNumber(phone_number)) {
            toast({ description: 'Please enter a valid number', status: 'error', duration: 5000, position: 'top', });
            return;
        }

        let remaining = (campaign?.product.retail_price ?? 0) - (campaign?.amount_raised ?? 0);
        if (Number(data.amount) <= 0) {
            return;
        }
        if (Number(data.amount) > remaining) {
            toast({
                title: "Amount exceeded",
                description: 'You can not pay more than GHS ' + remaining,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            return;
        }
        const paystack = new PaystackPop();
        setLoading(true);
        ctx?.set({ ...ctx.get, user_data: data })


        paystack.newTransaction({
            key: PAYSTACK_KEY_PROD,
            email: String(data.full_name).replaceAll(' ', '') + '@motito.co',
            amount: Number(data.amount) * 100,
            ref: String(Math.floor(Math.random() * 100000000)).replace('.', ''),
            currency: 'GHS',
            channels: ['card', 'mobile_money'],
            metadata: {
                custom_fields: [
                    {
                        ...data, type: 'pay4me', campaign_id: params.id, campaign_url: window.location.href, phone_number: phone_number.replaceAll(' ', '')
                    }
                ],
            },
            onSuccess: (transaction: any) => {
                setLoading(false)
                toast({
                    title: 'Transaction successful.',
                    description: "Transaction was successful",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                })
                navigate('/success');

            },
            onCancel: () => {
                setLoading(false)
                toast({
                    title: 'Transaction cancel.',
                    description: "Transaction failed, please try again",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                })
            }

        });
    }

    function getData() {
        if (ctx?.get) {
            setLoading(true);
            setCampaign(ctx?.get!);
            setLoading(false);
        } else {
            navigate('/' + params.id);
        }
    }

    let totalPercentage = ((campaign?.amount_raised ?? 0) / (campaign?.product.retail_price ?? 0) * 100);


    return (
        <div>
            <Header />
            {loading ?
                <div className='w-full flex justify-center mt-16 ' >
                    <Spinner className='' />
                </div> :
                <div className='max-w-[500px] px-4 mx-auto space-y-4 mt-3 mb-10'>
                    <div>
                        <Link to={'/' + params.id}>
                            <Button variant={'outline'} rounded={'full'} leftIcon={<ArrowLeft />}>
                                Go Back
                            </Button>
                        </Link>
                    </div>
                    <div className='bg-[#F9F9FB] p-[20px] rounded-[10px]'>
                        <p className='text-[#0E1736] font-bold text-2xl mb-5'> {campaign?.title} </p>
                        <div className='flex  items-center'>
                            <div className='w-[134px] h-[107px] overflow-clip rounded-[10px]'>
                                <img className='w-full h-full' src={campaign?.product.image_url.url} alt="" />
                            </div>
                            <div className='space-y-2 ml-[14px]'>
                                <p>{campaign?.product.name}</p>

                                <p className='text-[20px] font-bold text-[#5C6278]'>₵{campaign?.product.retail_price}</p>
                            </div>
                        </div>
                    </div>

                    <div className=' p-3'>
                        <p> <span className='font-bold text-[#0E1736]'  >GHS {campaign?.amount_raised?.toLocaleString() ?? 0.0} </span> raised of GHS {campaign?.product.retail_price}</p>
                        <Progress size={'sm'} value={totalPercentage} rounded={'md'} colorScheme='green' color={'#4CB27E'} bgColor={'#EFF0F6'} />
                    </div>

                    <Divider paddingBottom={'2'} />

                    <form onSubmit={handleSubmit(onSubmitForm)} className='space-y-4'>

                        <div >
                            <Controller name='amount' control={control} rules={{ required: true, min: 1, }} render={(field) => <FormControl >
                                <FormLabel>Enter your donation</FormLabel>
                                <InputGroup size={'lg'} >
                                    <InputLeftAddon bgColor={'transparent'} children='GHS' />
                                    <NumberInput parse={(v) => parseInt(v).toString()} pattern={`^[0-9]+$`}  {...field.field} >
                                        <NumberInputField paddingInlineEnd={'0'} outline={'none'}
                                            borderLeft={'transparent'} className='font-bold text-right' placeholder='' />
                                    </NumberInput>
                                    {/* <InputRightAddon bgColor={'transparent'} fontWeight={'bold'} children='.00' /> */}
                                </InputGroup>
                            </FormControl>} />

                            <div className='flex justify-start items-center gap-2 mt-3 flex-wrap'>
                                {
                                    items.map(v =>
                                        <Button key={v.toString()} className={Number(formData.amount) != v ? 'border-[#D6D8E6] border bg-[#F9F9FB]' : 'bg-[#0066F533] border border-[#0066F5]'} onClick={() => {

                                            setValue('amount', v)
                                        }}>
                                            GHS {v}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                        <Divider paddingBottom={'1'} />

                        {/* <div>
                            <p className='text-[#0E1736] font-bold mb-2'>Payment Method</p>

                            <div>
                                <Controller control={control} name='method' render={(field) => <RadioGroup {...field.field} size={'lg'} defaultValue='2' border={'1px'} borderColor={'#D6D8E6'} >
                                    <Radio paddingY={'20px'} paddingX={'16px'} onClick={() => setPaymentMethod('momo')} value='momo'>
                                        <div onClick={() => setPaymentMethod('momo')} className='text-left' >
                                            <p className='font-bold text-base'>Mobile Money</p>
                                            <p className='font-[400] text-[13px] text-[#5C6278]'>
                                                Pay using MTN, Vodafone and Airtel-Tigo <br /> mobile money account.
                                            </p>
                                        </div>
                                    </Radio>
                                    <Divider />
                                    <Radio paddingY={'20px'} paddingX={'16px'} onClick={() => setPaymentMethod('card')} value='card'>
                                        <div onClick={() => setPaymentMethod('card')} className='text-left '>
                                            <p className='font-bold text-base'>Visa/Debit cards</p>
                                            <p className='font-[400] text-[13px] text-[#5C6278]'>
                                                Pay using any debit or visa card from your <br /> bank.
                                            </p>
                                        </div>
                                    </Radio>

                                </RadioGroup>} />
                            </div>
                        </div>
                        <Divider /> */}
                        <div className='mb-2'>
                            <p className='text-[#0E1736] text-base font-bold '>Contributors details</p>
                            <p className='text-[#101E32] text-sm'>This will help us or fundraiser than you for your support. </p>
                        </div>
                        <div className=''>
                            <Controller name='full_name' rules={{ required: true, }} control={control} render={(field) => <FormControl size={'lg'}>
                                <FormLabel className='text-sm' >Your fullname</FormLabel>
                                <Input placeholder='eg: Tommy' {...field.field} size={'lg'} />
                            </FormControl>} />
                        </div>
                        <div className='phone_number'>
                            <FormControl size={'lg'}>
                                <FormLabel className='text-sm' >Phone number</FormLabel>
                                <IntlTelInput defaultCountry='gh'
                                    fieldName='phone_number'
                                    containerClassName="intl-tel-input w-full "
                                    inputClassName="form-control chakra-intl-phone"
                                    onPhoneNumberChange={(v, value, country, full_number) => {
                                        if (v) {
                                            setPhoneNumber(full_number)
                                        }
                                        return
                                    }}
                                />
                            </FormControl>

                        </div>
                        <div>
                            <Controller name='display_name' control={control} render={(field) => <FormControl display='flex' gap={'1rem'} alignItems='center'>
                                <Switch {...field.field} size={'lg'} id='email-alerts' />

                                <FormLabel htmlFor='email-alerts' fontSize={'14'} color={'#0E1736'} mb='0'>
                                    Don't display my name to the fundraiser. (Remain unknown to fundraiser).
                                </FormLabel>
                            </FormControl>} />
                        </div>
                        <div className='pt-4'>
                            <Button isLoading={loading} className='disabled:bg-[#EAECEE] disabled:text-[#9297A4] bg-[#0B419C] text-white'
                                disabled={!isValid} type='submit' width={'full'} variant={'solid'} size={'lg'} >
                                Donate now (GHS {formData.amount ?? 0})
                            </Button>
                            {/* <PaystackConsumer publicKey={PAYSTACK_KEY}
                                reference={Date.now().toString()}
                                amount={getValues('amount') * 100}
                                email={getValues('email')}
                                firstname={getValues('full_name')}
                                currency={'GHS'}
                                channels={['mobile_money', 'card']}
                                metadata={{??0
                                    custom_fields: [],
                                    ...getValues(),
                                }} >
                                {({ initializePayment, }) => <Button onClick={() => {
                                    console.log(getValues('email')); initializePayment(onSuccess, onClose);
                                }} disabled={!formState.isValid} size={'lg'} width={'full'} >

                                </Button>}
                            </PaystackConsumer> */}

                            {/* <PaystackButton publicKey={PAYSTACK_KEY} email='paulboamah22@gmail.com'  >
                                Donate now
                            </PaystackButton> */}
                        </div>
                    </form>
                    <div className='mt-4'>
                        <p className='text-sm text-[#0E1736]' >By continuing, you agree with Motito’s  <a target='_blank' href="https://motito.co/terms-and-conditions.html">terms</a> and <a target='_blank' href="https://motito.co/privacy-policy.html">privacy</a> notice.</p>
                    </div>
                    {/* <div className='mt-20'>
                    <p className='text-[#5C6278] text-sm mb-1'>Fundraiser</p>
                    <div className='flex bg-[#F9F9FB] rounded-[20px] gap-2 p-3 text-sm'>
                        <Avatar />
                        <div >
                            <p className='font-[500]'>Tobi Martins</p>
                            <p className='font-normal'>+233 273819737</p>
                        </div>
                    </div>
                </div> */}


                    {/* <div>
                    <Button bgColor={'#2E384B'} fontSize={'sm'} fontWeight={'normal'} color={'white'} rounded={'full'} className='w-full text-sm font-normal' onClick={() => {
                        window.open('https//:motito.co/', '_blank');
                    }}  >
                        Learn more about motito pay for me.
                    </Button>
                </div> */}
                </div >}
        </div >
    )
}


export default CampaignPayment;


{/* <RadioGroup value={paymentMethod} size={'lg'} defaultValue='2' border={'1px'} borderColor={'#D6D8E6'} >

<Accordion>
    <AccordionItem>

        <AccordionButton >
            <Radio onClick={() => setPaymentMethod('momo')} value='momo'>
                <div className='text-left' >
                    <p className='font-bold text-base'>Mobile Money</p>
                    <p className='font-[400] text-[13px] text-[#5C6278]'>
                        Pay using MTN, Vodafone and Airtel-Tigo <br /> mobile money account.
                    </p>
                </div>
            </Radio>
        </AccordionButton>

        <AccordionPanel pb={4}>
            <div>
                <div className=''>
                    <FormControl size={'lg'}>
                        <FormLabel>Mobile money network</FormLabel>
                        <Input size={'lg'} />
                    </FormControl>
                </div>
                <div>
                    <FormControl size={'lg'}>
                        <FormLabel>Enter your phone number here...</FormLabel>
                        <Input size={'lg'} />
                    </FormControl>
                </div>
            </div>
        </AccordionPanel>
    </AccordionItem>

    <AccordionItem>

        <AccordionButton>
            <Radio onClick={() => setPaymentMethod('card')} value='card'>
                <div className='text-left'>
                    <p className='font-bold text-base'>Visa/Debit cards</p>
                    <p className='font-[400] text-[13px] text-[#5C6278]'>
                        Pay using any debit or visa card from your <br /> bank.
                    </p>
                </div>
            </Radio>
        </AccordionButton>

        <AccordionPanel pb={4}>
            <div>
                <div className=''>
                    <FormControl size={'lg'}>
                        <FormLabel>Mobile money network</FormLabel>
                        <Input size={'lg'} />
                    </FormControl>
                </div>
                <div>
                    <FormControl size={'lg'}>
                        <FormLabel>Enter your phone number here...</FormLabel>
                        <Input size={'lg'} />
                    </FormControl>
                </div>
            </div>
        </AccordionPanel>
    </AccordionItem>
</Accordion>

</RadioGroup> */}