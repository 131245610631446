
import { Button } from '@chakra-ui/react';
import React, { Component } from 'react'

function ErrorView({ retry }: { retry?: Function }) {
    return (
        <div className='max-w-[500px] px-4 mx-auto space-y-4 mt-5 mb-10 text-center'>
            <p>Campaign Not Found</p>
            <Button variant='outline' onClick={() => {
                if (retry) {
                    retry()
                }
            }}>
                Retry
            </Button>
        </div>
    )
}

// export class ErrorBoundary extends Component {
//     constructor(props: any) {
//         super(props);
//         this.state = { hasError: false };
//     }

//     static getDerivedStateFromError(error: any) {
//         // Update state so the next render will show the fallback UI.
//         return { hasError: true };
//     }

//     componentDidCatch(error: any, info: any) {
//     }

//     render() {
//         if (this.state?.hasError as any) {
//             // You can render any custom fallback UI
//             return ErrorView();
//         }

//         return this.props?.children;
//     }
// }

export default ErrorView;