import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc, collection, getCountFromServer, } from 'firebase/firestore'
import { firebaseConfigDEV, firebaseConfigPROD } from "./constant";


export const app = initializeApp(firebaseConfigPROD);

const FirebaseFirestore = getFirestore(app);

export async function getCampaignById(id: string) {

    let x = await getDoc(doc(FirebaseFirestore, 'campaigns', id))
    let contributions = collection(FirebaseFirestore, `campaigns/${id}/contributions`);

    return {
        ...x.data(),
        product: (await getDoc(doc(FirebaseFirestore, 'inventory', x.data()?.productId))).data(),
        totalContributions: (await getCountFromServer(contributions)).data().count,
    }
}

