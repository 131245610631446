
export const PAYSTACK_KEY = 'pk_test_a01ca4b37e76d20e0957a9ba14fd4c243da20710';
export const PAYSTACK_KEY_PROD = 'pk_live_2aa93dc76cf67fb135d4ceb99a9b5eb7ea361d79';
export const firebaseConfigDEV = {
    apiKey: "AIzaSyChDh_uyoPfMHcba4CiXZ_oyJRNKQ6XItk",
    authDomain: "motito-paylater-dev.firebaseapp.com",
    databaseURL: "https://motito-paylater-dev.firebaseio.com",
    projectId: "motito-paylater-dev",
    storageBucket: "motito-paylater-dev.appspot.com",
    messagingSenderId: "191182144371",
    appId: "1:191182144371:web:4d4ba86a12af957a7fbb06",
    measurementId: "G-QD18YDL3D3"
};

export const firebaseConfigPROD = {
    apiKey: "AIzaSyCoC_FcHUEONFAZiK2pYSnglJJIPMCOSoI",
    authDomain: "motito-paylater-prod.firebaseapp.com",
    databaseURL: "https://motito-paylater-prod.firebaseio.com",
    projectId: "motito-paylater-prod",
    storageBucket: "motito-paylater-prod.appspot.com",
    messagingSenderId: "1076218658636",
    appId: "1:1076218658636:web:af3c446fb6f1e2df8659e9",
    measurementId: "G-W844P296ZJ"
};