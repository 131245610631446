"use client"

import { Avatar, Button, Divider, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Toast, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Facebook, Instagram, Linkedin, Twitter } from 'react-bootstrap-icons'
import { Link, useLoaderData, useLocation, useNavigate, useParams, redirect } from 'react-router-dom'
import { getCampaignById } from '../services/firebase'
import { Spinner } from '@chakra-ui/react'
import { Campaign } from '../interface'
import moment from 'moment';
import { GlobalContext } from '../App'
import Header from './header'
import thankyou from '../assets/svgs/thank_you.svg';
import facebook from '../assets/svgs/facebook.svg';
import twitter from '../assets/svgs/twitter.svg';
import whatsapp from '../assets/svgs/whatsapp.svg';
import ErrorView from './not_found'

export default function CampaignDetails() {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(true)
    const [isError, setIsError] = useState(false);
    // const [campaign, setCampaign] = useState<Campaign | undefined>();
    const ctx = useContext(GlobalContext);
    const toast = useToast()
    var campaign = ctx?.get;

    useEffect(() => {
        localStorage.removeItem('MOTITO')
        getData();
        // if (!ctx?.get) {
        //     getData();
        // } else {
        //     ctx.set({
        //         ...ctx?.get, url: window.location.href
        //     });
        //     setLoading(false);
        // }
    }, [])

    function getData() {
        setIsError(false);
        if (params.id) {
            setLoading(true);
            getCampaignById(params.id).then((v: any) => {
                if (v) {
                    console.log(v);
                    ctx?.set({ ...v, createdAt: moment(v?.createdAt?.toDate()).fromNow(), url: window.location.href } as any)
                    // ctx?.set({ ...(v as any), url: document.referrer })

                } else {

                }
            }).finally(() => {
                setLoading(false);
            }).catch(r => {
                setIsError(true);
                console.error(r);
            })
        } else {
            redirect('https://motito.co');
        }
    }

    let totalPercentage = ((campaign?.amount_raised ?? 0) / (campaign?.product.retail_price ?? 0) * 100);

    return (

        <div className='' >

            <Header />
            {isError ? <ErrorView retry={getData} /> : loading ?
                <div className='w-full flex justify-center mt-16 ' >
                    <Spinner className='' />
                </div> :

                <div className='max-w-[500px] px-4 mx-auto space-y-4 mt-3 mb-10'>
                    <div className='bg-[#F9F9FB] p-[20px] rounded-[10px]'>
                        <p className='text-[#0E1736] font-bold text-2xl mb-5'>{campaign?.title ?? ""}</p>
                        <div className='flex  items-center'>
                            <div className='w-[134px] h-[107px] overflow-clip rounded-[10px]'>
                                <img className='w-full h-full object-cover' src={campaign?.product.image_url.url} alt="" />
                            </div>
                            <div className='space-y-2 ml-[14px]'>
                                <p>{campaign?.product.name}</p>

                                <p className='text-[20px] font-bold text-[#5C6278]'>₵{campaign?.product.retail_price.toFixed(2) ?? ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className=' p-3'>
                        <p> <span className='font-bold text-[#0E1736]'  >GHS {campaign?.amount_raised?.toLocaleString() ?? 0.0} </span> raised of GHS {campaign?.product.retail_price}</p>
                        <Progress size={'sm'} value={totalPercentage} rounded={'md'} colorScheme='green' color={'#4CB27E'} bgColor={'#EFF0F6'} />
                    </div>

                    <div className='flex gap-4' >
                        <div className='bg-[#F9F9FB] p-[10px] rounded-full'>
                            <p>{campaign?.createdAt.toString()}</p>
                        </div>


                        <div className='bg-[#F9F9FB] p-[10px] rounded-full'>
                            <p>{campaign?.totalContributions ?? 0} Donation{campaign?.totalContributions > 1 ? 's' : ''} </p>
                        </div>
                    </div>
                    <Divider />
                    <div className='flex justify-center gap-4 flex-col md:flex-row text-sm mt-[24px]'>


                        <Button onClick={() => {
                            if (totalPercentage < 100) {

                                navigate('/pay/' + params.id)
                            } else {
                                toast({
                                    title: "Donation Ended",
                                    description: 'Please donation has been closed',
                                    duration: 5000,
                                    position: 'top'
                                })
                            }
                        }} disabled={totalPercentage >= 100} width={'full'} size={'lg'} variant='solid' textColor={'white'} className=' text-white hover:bg-opacity-90 bg-[#0B419C]' >
                            <p>Donate</p>
                        </Button>

                        <Button borderColor={'#0E1736'} onClick={onOpen} width={'full'} size={'lg'} variant='outline'>
                            <p>Share</p>
                        </Button>
                    </div>
                    <div>
                        <p className='text-[#0E1736] font-bold mb-2'>About Campaign</p>
                        <p className='text-[#101E32] text-sm'>
                            {campaign?.description}
                        </p>
                    </div>
                    <div>
                        <p className='text-[#5C6278] text-sm mb-1'>Fundraiser</p>
                        <div className='flex bg-[#F9F9FB] rounded-[20px] gap-2 p-3 text-sm'>
                            <Avatar src={campaign?.user_avatar} />
                            <div >
                                <p className='font-[500]'>{campaign?.user_name}</p>
                                <p className='font-normal'>{campaign?.user_phone_number}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Button onClick={() => {
                            window.open('https://motito.co', '_blank')
                        }} fontSize={'sm'} fontWeight={'normal'} color={'white'} rounded={'full'} className='w-full text-sm font-normal bg-[#2E384B] hover:bg-opacity-90'  >
                            Learn more about motito pay for me.
                        </Button>
                    </div>
                </div>}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <p className='text-xl font-bold text-[#2E384B]'>Help spread the word.</p>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='max-w-[500px] px-4 mx-auto space-y-4 mt-3 mb-10 '>

                            <div className='text-center'>

                                <p className='text-lg'>
                                    You can share this campaign to spread the word and help <span className='font-bold'>{campaign?.user_name} </span> reach his target faster.
                                </p>
                            </div>

                            <div >
                                <p className='text-sm'>Copy Link </p>
                                <div className='flex gap-3'>
                                    <Input value={window.location.href} className='border p-4 rounded-md flex-auto' />
                                    <Button className=' text-white hover:bg-opacity-90 bg-[#0B419C]' onClick={() => {
                                        navigator.clipboard.writeText(window.location.href ?? '');
                                    }} >
                                        Copy
                                    </Button>
                                </div>
                            </div>

                            <div className='flex gap-8 w-fit mx-auto text-center'>
                                <div onClick={() => {
                                    window.open(`https://wa.me/?text=${window.location.href}`, '_blank')
                                }} className='w-fit cursor-pointer' >
                                    <img className='mx-auto' src={whatsapp} alt="" />
                                    <p>What's App</p>
                                </div>

                                <div onClick={() => {
                                    window.open(`http://www.facebook.com/sharer/sharer.php?t=${window.location.href}`, '_blank')
                                }} className='w-fit cursor-pointer'>
                                    <img className='mx-auto' src={facebook} alt="" />
                                    <p>Facebook</p>
                                </div>

                                <div onClick={() => {
                                    window.open(`http://www.twitter.com/intent/tweet?text=${window.location.href}`, '_blank')
                                }} className='w-fit cursor-pointer'>
                                    <img className='mx-auto' src={twitter} alt="" />
                                    <p>Twitter</p>
                                </div>

                            </div>

                        </div >
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} variant='ghost'> Close </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </div>
    )
}
