import React, { createContext, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import OrderDetails from './order-details/page';
import CampaignDetails from './order-details/page';
import CampaignPayment from './order-details/payment';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import ErrorView from './order-details/not_found';
import Success from './order-details/success';
import { Campaign } from './interface';
import { extendTheme } from '@chakra-ui/react'

export const GlobalContext = createContext<{ get: Campaign | null, set: (campaign: Partial<Campaign>) => void } | null>(null);


const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
})


const router = createBrowserRouter([
  {
    path: '/',
    element: <ErrorView />
  },
  {
    path: "/:id",
    element: <CampaignDetails />,
  },
  {
    path: 'pay/:id',
    element: <CampaignPayment />
  },
  {
    path: 'success',
    element: <Success />
  }
]);

function App() {
  const [details, setDetails] = useState(localStorage.getItem('MOTITO') == null ? null : JSON.parse(localStorage.getItem('MOTITO')!));
  return (
    <GlobalContext.Provider value={{
      get: details, set: (arg) => {
        setDetails(arg as any)
        localStorage.setItem('MOTITO', JSON.stringify(arg))
      }
    }} >

      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider >
    </GlobalContext.Provider>

  );
}

export default App;
